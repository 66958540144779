@import url(https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Open+Sans:wght@400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
:root {
    --spacing-small: 12px;
    --spacing-medium: calc(var(--spacing-small) * 2);
    --spacing-half: calc(var(--spacing-small) * .5);
    --spacing-big: calc(var(--spacing-small) * 2.5);
    --padding: calc(var(--spacing-medium));
    --padding-small: calc(var(--spacing-small));
    --padding-quiz: calc(var(--spacing-small) * 1.75);
    --padding-title: calc(var(--spacing-small) * 2);
    --correct-color: #aeee7e;
    --incorrect-color: #fe8a8a;
    --mobile-size: 500px;
    --bgd: #7e90ee;
    --bgd-light: #c5cdfa;

    --bcg1: #a9ee7e;
}
h1, h2, div, a {
    word-break: break-word;
}

body {
  font-family: "Exo 2";
/*  */
  /* font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif */
}
.mb {
    margin-bottom: var(--spacing-medium) !important;
    display: block;
}

.masonry {
    column-count: 2;
    column-gap: var(--spacing-small);
    width: 100% + var(--spacing-medium);
    margin: 0 calc(-1 * var(--spacing-small)); /* Center the masonry grid */
}

.masonryItem {
    margin: 0;
    padding: 0 var(--spacing-small);
    display: inline-block; /* Make items flow nicely */
    width: 100%;
    box-sizing: border-box;
    page-break-inside: avoid;
    break-inside: avoid;
}

.arrowDown {
    rotate: -90deg;
    margin-top: 10px;
    float: left;
}
.right_info {
    float: right;
    margin-top: 5px;
}

.masonryItem a {
    display: block;
    width: 100%;
    position: relative;
}

@media screen and (max-width: 500px) {
    /*:root {*/
    /*    --padding: calc(var(--padding));*/
    /*}*/
    .mainLogoTitle {
        text-align: center;
    }
    .mainLogo {
        display: block;
        margin: 0 auto;
        padding-bottom: 10px;
        width: 100%;
    }

    .menu_item_span {
        display: none!important;
    }
    .masonry {
        column-count: 1;
    }
    body {
        padding-left: var(--spacing-small)!important;
        padding-right: var(--spacing-small)!important;
    }
    .card2 {
        display: block!important;
        text-align: center;
        width: 100%;
        margin: 0 auto;
    }
    .card2 > div, .card2 > a{
        width: 100%!important;
        margin-bottom: var(--padding);

    }
    .card2 > div:last-child {
        margin-bottom: 0!important;
    }
}

main {
  padding-bottom: 20px;
}
.mainLogo {
  float: left;
  margin-right: 10px;
  margin-top: -5px;
}
body {
    max-width: 800px;
    margin: 0 auto;

    /* background: linear-gradient(135deg, #fcfcfc92, #eff7ef76); */
    padding: var(--spacing-medium);
    padding-bottom: 90px;
    background-color: #1d1c1c;
    overflow-y: scroll;
}

.box {
    width: 50%; /* You can adjust the width as per your needs */
    padding-bottom: 150%; /* This is 2x the width, creating an aspect ratio of 2:1 for height:width */
    position: relative; /* This is to position content inside if required */
}

.box-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: white; /* Assuming the box background is dark */
    /* Add other styles as needed */
}


.pull-right {
    float: right;
}

input {
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    font-size: 18px;
    margin-bottom: var(--spacing-medium);
    color: #afb4be;
    /* width: 110%; */
}


.moreLink,
.mainLink {
    padding: 10px 0;
    cursor: pointer;
    text-decoration: underline;
    width: 100%;
}

.mainLink img {
    rotate: -90deg;
}

.mainLink {
    display: -ms-flexbox;
    display: flex;
    padding: 24px;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    gap: 12px;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    margin-bottom: 20px;
    color: #1d1c1c;
    text-decoration: none;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 88.889% */
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius: 28px;
    background: #c4fe8a;
    box-sizing: border-box;
}


.card {
    height: auto;
    display: inline-block;
    /*flex-direction: column;*/
    padding: var(--padding);
    box-sizing: border-box;
    border-radius: 24px;
    color: #e8e8e8;
    background-color: #343333;
    width: 100%;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px; /* 118.75% */
    letter-spacing: 1px;
}

.card h1 {
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-align: center;
        align-items: center;
    margin-top: -5px!important;
}

.card input {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
    gap: var(--spacing-medium);
    -ms-flex-negative: 0;
        flex-shrink: 0;
    padding: 24px;
    border-radius: 28px;
    border: 1px solid #c4fe8a;
    background: #343333;
}

.card_title {
    padding-top: var(--spacing-big) !important;
    padding-bottom: var(--spacing-big) !important;
}
.w100 {
  width: 100%!important;
}
a, button {
  cursor: pointer!important;
}
.card button, button {
    cursor: poitner;
    width: 100%;
    height: 72px;
    padding: 24px;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    gap: 12px;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    border: 1px solid #c4fe8a;
    border-radius: 28px;
    background: #c4fe8a;
    color: #1d1c1c;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 88.889% */
    letter-spacing: 1px;
    text-transform: uppercase;
}

.card2 {
    display: -ms-flexbox;
    display: flex;
}

.card small {
    display: none;
}

.card_purple {
  background: var(--bgd);
}

.card_green {
    background: var(--bcg1);
    color: rgb(29, 28, 28)!important;
}

.card_green div {
    width: 50%;
}

.padding_right {
    padding-right: var(--padding-small);
    backgroudn: red;
}

.card_green div:last-child {
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    padding: 12px 0px;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    border-radius: 16px;
}

.card__desc div img {
    max-width: 100%;
    height: auto;
}

a .card {
    color: white;
    -ms-flex-align: center;
        align-items: center;
    width: 100%;
}

a .card span:first-child {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 73.333% */
    letter-spacing: 1px;
}

a .card span:last-child {
    font-size: 12px;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 133.333% */
    letter-spacing: -0.48px;
    text-transform: uppercase;
}

.noBackground {
    background-color: transparent;
    box-shadow: none;
    -ms-flex-align: center;
        align-items: center;
    padding: 8px 0px 0px 0px;

    gap: 3px;
    color: white;
}

.cardParagraph {
    margin-top: 3px;
    margin-bottom: 16px;
}

h1 {
    color: #fdffff !important;
    font-size: 30px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 100% !important;
    letter-spacing: 1px !important;
    margin: 0 !important;
}

hr {
    border: none; /* Remove default border */
    height: 1px; /* Set the height of the line */
    background-color: rgba(227, 227, 227, 0.2); /* Set the color of the line */
    margin: 20px 0; /* Add some spacing above and below the line */
}

img {
    max-width: 100%;
}

.imageWrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    margin: 10px 0;
    padding: 20px;
    background-color: white;
    border-radius: 32px;
}

.pull-right {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
}

.quizWrapper {
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    gap: 12px;
}

.nextQuestion {
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding: 24px;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    gap: 12px;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    border-radius: 28px;
    background: #c4fe8a;
    text-transform: uppercase;
}

.arrowWrapper {
    display: -ms-flexbox;
    display: flex;
    padding: 9px;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: start;
        align-items: flex-start;
    gap: 10px;
    border-radius: 18px;
    /* border: 1px solid #c4fe8a; */
    background: var(--bcg1);
}

.cover {
    width: 100%;
    height: 100%;
    background-size: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1 !important;
    border-radius: 35px;
}

.cardImg::after {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
            360deg,
            rgba(0, 0, 0, 0.95) 0%,
            rgba(0, 0, 0, 0.5) 30%,
            rgba(0, 0, 0, 0) 100%
    )

}

.cardImg > div, .cardImg > a {
    z-index: 1;
}

.cardImg {
    position: relative;
    overflow: hidden;
    display: -ms-flexbox;
    display: flex;
    height: 475px;
    padding: 20px 16px 24px 16px;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-align: start;
        align-items: flex-start;
    border-radius: 32px;
    margin-bottom: 12px;
    color: white;
    /* H2 */
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 107.143% */
}

h2 {
    margin: 6px !important;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 107.143% */
    letter-spacing: 0;
}

h3 {
    margin: 0 !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 2px;
}

.card h3 {
    margin-bottom: 10px !important;
}


.footer__DGBa9 {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 10000;
  text-align: center;
}
.footer__DGBa9 nav {
  box-shadow: 0 0 10px 5px #1d1c1c33;
  display: -ms-flexbox;
  display: flex;
  max-width: 800px;
  -ms-flex-align: center;
      align-items: center;
  margin: 0 16px 16px 16px;
  height: 80px;
  background: var(--bgd);
  border-radius: 32px;
  margin: 0 auto;
  /* box-shadow: 2px 2px 7px 7px #efefef; */
}

.footer__DGBa9 a {
  width: 25%;
}
.active__O32Hl {
  text-decoration: none;
}

.menu_item__0epRa {
  display: -ms-flexbox;
  display: flex;
  padding: 12px 0px 16px 0px;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  gap: 9px;
  -ms-flex: 1 0 0px;
      flex: 1 0 0;
  color: #d2d9e7;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  /* font-weight: 600; */
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.5px;

}


.menu_item__icon_dates__hceO\+ {
  width: 24px;
  height: 24px;
  background-image: url(/076a85a37338e66c997d7bbf005d8e60.svg);
}

.menu_item__icon_quiz__qxQR7 {
  width: 24px;
  height: 24px;
  background-image: url(/9bc77910f29381d5e63f08af63dffb66.svg);
}

.menu_item__icon_about__-Nzuq {
  width: 24px;
  height: 24px;
  background-image: url(/6e534d70b9c5120100d9b35b7fcb57c1.svg);
}

.menu_item__icon_profile__cg3YE {
  width: 24px;
  height: 24px;
  background-image: url(/b176ef6b17a11e71c7ac730c41471ab0.svg);
}

a {
  text-decoration: none;
}

.qr__Uia4M {
  display: -ms-flexbox;
  display: flex;
  border-radius: 1rem;
  background-color: black;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 2rem;
}


.title__2\+Mdo {
    margin-top: 10px;
    font-weight: bold;
    /* padding: 24px; */
    font-size: 18px;
    text-decoration: none !important;
    color: #fff;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}

.list__T1JCn {
    /* padding: 5px; */
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
}



.img__LzySG {
    /*width: 10.4em;*/
    /*height: 15.7em;*/
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 32px;
    background: linear-gradient(
            360deg,
            rgba(18, 25, 28, 0.75) 0%,
            rgba(15, 21, 23, 0) 100%
    ),
    #c4c4c4;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.card__YJjsb {
    margin-bottom: 25px;
    margin-top: 6px;
    /* background: white;
    border-radius: 21px;
    box-shadow: 2px 2px 16px 0px #80808029; */
    overflow: hidden;
    /* padding: 25px; */
}

.card_items__M5chz {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-top: 50px;
    -ms-flex-pack: justify;
        justify-content: space-between;
    gap: 8px;
}

.card__YJjsb div:nth-child(even) {
    margin-top: -94px;
}

.card_item__AAB84 {
    /* flex-basis: calc(
      50% - 10px
    ); Ширина элемента, учтите отступы между элементами */
    /* margin: 5px; */
}

button {
    margin-bottom: 40px !important;
    /* margin-left: 16px !important; */
    width: 7rem;
    border: none;
}

.answered__VWEO0 {
  /* border: 1px solid grey; */
  cursor: default;
}

.vItem__c3nTu {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer;
  padding: var(--padding-quiz);
  border-radius: 20px;
  transition: all 0.4s ease;
  color: #1d1c1c;
  background-color: var(--bgd-light);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}

.answered__VWEO0 .vItem__c3nTu {
  cursor: default;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.vAnswered__qVcL5 {
  background: var(--incorrect-color);
}

.correct__kIzsr,
.vAnswered__qVcL5.correct__kIzsr {
  background: var(--correct-color);
}


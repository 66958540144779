.title {
    margin-top: 10px;
    font-weight: bold;
    /* padding: 24px; */
    font-size: 18px;
    text-decoration: none !important;
    color: #fff;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}

.list {
    /* padding: 5px; */
    display: flex;
    justify-content: center;
}



.img {
    /*width: 10.4em;*/
    /*height: 15.7em;*/
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 32px;
    background: linear-gradient(
            360deg,
            rgba(18, 25, 28, 0.75) 0%,
            rgba(15, 21, 23, 0) 100%
    ),
    #c4c4c4;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.card {
    margin-bottom: 25px;
    margin-top: 6px;
    /* background: white;
    border-radius: 21px;
    box-shadow: 2px 2px 16px 0px #80808029; */
    overflow: hidden;
    /* padding: 25px; */
}

.card_items {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    justify-content: space-between;
    gap: 8px;
}

.card div:nth-child(even) {
    margin-top: -94px;
}

.card_item {
    /* flex-basis: calc(
      50% - 10px
    ); Ширина элемента, учтите отступы между элементами */
    /* margin: 5px; */
}

button {
    margin-bottom: 40px !important;
    /* margin-left: 16px !important; */
    width: 7rem;
    border: none;
}

.answered {
  /* border: 1px solid grey; */
  cursor: default;
}

.vItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: var(--padding-quiz);
  border-radius: 20px;
  transition: all 0.4s ease;
  color: #1d1c1c;
  background-color: var(--bgd-light);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}

.answered .vItem {
  cursor: default;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.vAnswered {
  background: var(--incorrect-color);
}

.correct,
.vAnswered.correct {
  background: var(--correct-color);
}


.footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 10000;
  text-align: center;
}
.footer nav {
  box-shadow: 0 0 10px 5px #1d1c1c33;
  display: flex;
  max-width: 800px;
  align-items: center;
  margin: 0 16px 16px 16px;
  height: 80px;
  background: var(--bgd);
  border-radius: 32px;
  margin: 0 auto;
  /* box-shadow: 2px 2px 7px 7px #efefef; */
}

.footer a {
  width: 25%;
}
.active {
  text-decoration: none;
}

.menu_item {
  display: flex;
  padding: 12px 0px 16px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 9px;
  flex: 1 0 0;
  color: #d2d9e7;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  /* font-weight: 600; */
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.5px;

}


.menu_item__icon_dates {
  width: 24px;
  height: 24px;
  background-image: url("../../assets/icons/svg/dates.svg");
}

.menu_item__icon_quiz {
  width: 24px;
  height: 24px;
  background-image: url("../../assets/icons/svg/quiz.svg");
}

.menu_item__icon_about {
  width: 24px;
  height: 24px;
  background-image: url("../../assets/icons/svg/about.svg");
}

.menu_item__icon_profile {
  width: 24px;
  height: 24px;
  background-image: url("../../assets/icons/svg/profile.svg");
}

a {
  text-decoration: none;
}

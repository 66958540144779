@import url("https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Open+Sans:wght@400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");


:root {
    --spacing-small: 12px;
    --spacing-medium: calc(var(--spacing-small) * 2);
    --spacing-half: calc(var(--spacing-small) * .5);
    --spacing-big: calc(var(--spacing-small) * 2.5);
    --padding: calc(var(--spacing-medium));
    --padding-small: calc(var(--spacing-small));
    --padding-quiz: calc(var(--spacing-small) * 1.75);
    --padding-title: calc(var(--spacing-small) * 2);
    --correct-color: #aeee7e;
    --incorrect-color: #fe8a8a;
    --mobile-size: 500px;
    --bgd: #7e90ee;
    --bgd-light: #c5cdfa;

    --bcg1: #a9ee7e;
}
h1, h2, div, a {
    word-break: break-word;
}

body {
  font-family: "Exo 2";
/*  */
  /* font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif */
}
.mb {
    margin-bottom: var(--spacing-medium) !important;
    display: block;
}

.masonry {
    column-count: 2;
    column-gap: var(--spacing-small);
    width: 100% + var(--spacing-medium);
    margin: 0 calc(-1 * var(--spacing-small)); /* Center the masonry grid */
}

.masonryItem {
    margin: 0;
    padding: 0 var(--spacing-small);
    display: inline-block; /* Make items flow nicely */
    width: 100%;
    box-sizing: border-box;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
}

.arrowDown {
    rotate: -90deg;
    margin-top: 10px;
    float: left;
}
.right_info {
    float: right;
    margin-top: 5px;
}

.masonryItem a {
    display: block;
    width: 100%;
    position: relative;
}

@media screen and (max-width: 500px) {
    /*:root {*/
    /*    --padding: calc(var(--padding));*/
    /*}*/
    .mainLogoTitle {
        text-align: center;
    }
    .mainLogo {
        display: block;
        margin: 0 auto;
        padding-bottom: 10px;
        width: 100%;
    }

    .menu_item_span {
        display: none!important;
    }
    .masonry {
        column-count: 1;
    }
    body {
        padding-left: var(--spacing-small)!important;
        padding-right: var(--spacing-small)!important;
    }
    .card2 {
        display: block!important;
        text-align: center;
        width: 100%;
        margin: 0 auto;
    }
    .card2 > div, .card2 > a{
        width: 100%!important;
        margin-bottom: var(--padding);

    }
    .card2 > div:last-child {
        margin-bottom: 0!important;
    }
}

main {
  padding-bottom: 20px;
}
.mainLogo {
  float: left;
  margin-right: 10px;
  margin-top: -5px;
}
body {
    max-width: 800px;
    margin: 0 auto;

    /* background: linear-gradient(135deg, #fcfcfc92, #eff7ef76); */
    padding: var(--spacing-medium);
    padding-bottom: 90px;
    background-color: #1d1c1c;
    overflow-y: scroll;
}

.box {
    width: 50%; /* You can adjust the width as per your needs */
    padding-bottom: 150%; /* This is 2x the width, creating an aspect ratio of 2:1 for height:width */
    position: relative; /* This is to position content inside if required */
}

.box-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: white; /* Assuming the box background is dark */
    /* Add other styles as needed */
}


.pull-right {
    float: right;
}

input {
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    font-size: 18px;
    margin-bottom: var(--spacing-medium);
    color: #afb4be;
    /* width: 110%; */
}


.moreLink,
.mainLink {
    padding: 10px 0;
    cursor: pointer;
    text-decoration: underline;
    width: 100%;
}

.mainLink img {
    rotate: -90deg;
}

.mainLink {
    display: flex;
    padding: 24px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
    margin-bottom: 20px;
    color: #1d1c1c;
    text-decoration: none;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 88.889% */
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius: 28px;
    background: #c4fe8a;
    box-sizing: border-box;
}


.card {
    height: auto;
    display: inline-block;
    /*flex-direction: column;*/
    padding: var(--padding);
    box-sizing: border-box;
    border-radius: 24px;
    color: #e8e8e8;
    background-color: #343333;
    width: 100%;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px; /* 118.75% */
    letter-spacing: 1px;
}

.card h1 {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: -5px!important;
}

.card input {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-medium);
    flex-shrink: 0;
    padding: 24px;
    border-radius: 28px;
    border: 1px solid #c4fe8a;
    background: #343333;
}

.card_title {
    padding-top: var(--spacing-big) !important;
    padding-bottom: var(--spacing-big) !important;
}
.w100 {
  width: 100%!important;
}
a, button {
  cursor: pointer!important;
}
.card button, button {
    cursor: poitner;
    width: 100%;
    height: 72px;
    padding: 24px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
    border: 1px solid #c4fe8a;
    border-radius: 28px;
    background: #c4fe8a;
    color: #1d1c1c;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 88.889% */
    letter-spacing: 1px;
    text-transform: uppercase;
}

.card2 {
    display: flex;
}

.card small {
    display: none;
}

.card_purple {
  background: var(--bgd);
}

.card_green {
    background: var(--bcg1);
    color: rgb(29, 28, 28)!important;
}

.card_green div {
    width: 50%;
}

.padding_right {
    padding-right: var(--padding-small);
    backgroudn: red;
}

.card_green div:last-child {
    display: flex;
    height: 100%;
    padding: 12px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
}

.card__desc div img {
    max-width: 100%;
    height: auto;
}

a .card {
    color: white;
    align-items: center;
    width: 100%;
}

a .card span:first-child {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 73.333% */
    letter-spacing: 1px;
}

a .card span:last-child {
    font-size: 12px;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 133.333% */
    letter-spacing: -0.48px;
    text-transform: uppercase;
}

.noBackground {
    background-color: transparent;
    box-shadow: none;
    align-items: center;
    padding: 8px 0px 0px 0px;

    gap: 3px;
    color: white;
}

.cardParagraph {
    margin-top: 3px;
    margin-bottom: 16px;
}

h1 {
    color: #fdffff !important;
    font-size: 30px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 100% !important;
    letter-spacing: 1px !important;
    margin: 0 !important;
}

hr {
    border: none; /* Remove default border */
    height: 1px; /* Set the height of the line */
    background-color: rgba(227, 227, 227, 0.2); /* Set the color of the line */
    margin: 20px 0; /* Add some spacing above and below the line */
}

img {
    max-width: 100%;
}

.imageWrapper {
    display: flex;
    justify-content: center;
    margin: 10px 0;
    padding: 20px;
    background-color: white;
    border-radius: 32px;
}

.pull-right {
    display: flex;
    flex-direction: row-reverse;
}

.quizWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.nextQuestion {
    display: flex;
    width: 100%;
    padding: 24px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
    border-radius: 28px;
    background: #c4fe8a;
    text-transform: uppercase;
}

.arrowWrapper {
    display: flex;
    padding: 9px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 18px;
    /* border: 1px solid #c4fe8a; */
    background: var(--bcg1);
}

.cover {
    width: 100%;
    height: 100%;
    background-size: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1 !important;
    border-radius: 35px;
}

.cardImg::after {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
            360deg,
            rgba(0, 0, 0, 0.95) 0%,
            rgba(0, 0, 0, 0.5) 30%,
            rgba(0, 0, 0, 0) 100%
    )

}

.cardImg > div, .cardImg > a {
    z-index: 1;
}

.cardImg {
    position: relative;
    overflow: hidden;
    display: flex;
    height: 475px;
    padding: 20px 16px 24px 16px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 32px;
    margin-bottom: 12px;
    color: white;
    /* H2 */
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 107.143% */
}

h2 {
    margin: 6px !important;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 107.143% */
    letter-spacing: 0;
}

h3 {
    margin: 0 !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 2px;
}

.card h3 {
    margin-bottom: 10px !important;
}
